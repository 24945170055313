<template>
  <div>
    <v-row justify="center">
      <v-select
        v-model="currentsp"
        :items="sp.specializations"
        :label="sp.name"
        class="shrink"
        item-text="name"
        :name="name"
        item-value="id"
        :rules="[v => !!v || `${sp.name} مطلوبة`]"
      ></v-select>
    </v-row>
    <v-row justify="center" v-if="nextsp">
      <selectsp :sp="nextsp" />
    </v-row>
  </div>
</template>
<script>
import { getfaculity } from "../scripts/faculity.js";
import selectsp from "./selectsp.vue";

export default {
  name: "selectsp",
  props: ["sp"],
  mounted() {
    document
      .getElementById("app")
      .scrollIntoView({ behavior: "smooth", block: "end" });
  },
  data: () => ({
    currentsp: "",
    name: "",
    nextsp: undefined
  }),
  components: {
    selectsp
  },
  watch: {
    sp: {
      immediate: true,
      handler() {
        this.nextsp = undefined;
      }
    },
    currentsp: {
      immediate: true,
      handler(val) {
        if (val) {
          var a = getfaculity(val);
          if (a.Done) {
            this.name = "sp";
            this.nextsp = undefined;
          } else {
            this.name = "";
            this.nextsp = a;
          }
        }
      }
    }
  }
};
</script>
