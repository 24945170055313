<template>
  <v-container fluid>
    <v-overlay :value="overlayLoad">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row class="justify-center">
      <v-col cols="10" sm="8" md="6" xl="4">
        <v-img
          contain
          :lazy-src="require('../assets/Military-baner-lazy.webp')"
          :src="require('../assets/Military-baner.webp')"
          id="logo"
        />
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-1 font-weight-bold mb-3">
          تقديم التربية العسكرية<br />جامعة اسوان
        </h1>
        <p
          v-if="!overlayLoad && !notyet"
          class="subheading font-weight-regular"
        >
          <br />
          <b style="color: blue">
            دورة التربية العسكرية
            <br />
            رقم 48
            <br />
            بتاريخ 30-11-2024 م حتي 12-12-2024 م
          </b>
          <br />
          <br />
          <b style="color: rgb(7, 7, 8)">
            بناء على اوامر السيد مدير اداره التربيه العسكريه بشان دورات التربيه
            العسكريه يتم تنفيذ دوره 30-11-2024 م فعلي في كليه الهندسه
            <br />
            وان التسجيل اونلاين فقط
            <br />
            لذى على الطلاب المقبولين فى التسجيل الإلكترونى ضرورة التواجد بكلية
            الهندسة الساعة 8:30 صباح السبت 30-11-2024 م و ذلك لتوضيح تعليمات
            إتمام الدورة
          </b>
          <br />
          <br />
          <!-- <b style="color: red">
            يرجى الأخذ فى الاعتبار
            <br />
            ان هذه الدورة تعد الدورة الأخيرة للفرق النهائية
            <br />
          </b> -->
        </p>
      </v-col>
    </v-row>
    <div v-if="!overlayLoad">
      <!-- all page-->
      <div v-if="notyet">
        <v-row justify="center">
          <h2 class="display-1">
            تم غلق التسجيل
            <br />
            لم يتم الاعلان عن دورات قادمة حتي الان
          </h2>
        </v-row>
      </div>
      <div v-if="regdate">
        <v-row justify="center">
          <p>متبقي علي التسجيل<br /></p>
        </v-row>
        <v-row justify="center">
          <h2 class="display-1">
            {{ regdate }}
          </h2>
        </v-row>
      </div>
      <div v-if="Full">
        <v-row justify="center">
          <p>تم اكتمال العدد , اجمالي المسجلين بالدورة : <br /></p>
        </v-row>
        <v-row justify="center">
          <h2 class="display-1">
            {{ Full }}
          </h2>
        </v-row>
      </div>
      <div v-if="user">
        <v-row justify="center">
          <h2 class="display-1">برجاء الانتظار ,وعدم اغلاق الصفحة</h2>
        </v-row>
        <v-row justify="center">
          <p>
            انت حاليا في قائمة الانتظار<br />
            دورك الحالي : {{ user.number }}<br />
            عدد الذين يسجلون بياناتهم حاليا : {{ user.crnt }}<br />
            انت في انتظار عدد{{ user.wait }}<br />
          </p>
        </v-row>
      </div>
      <div v-if="finisheduser">
        <v-row justify="center">
          <h2 class="display-1">تم التسجيل بنجاح</h2>
        </v-row>
        <v-row justify="center">
          <p>
            الرقم القومي :<br /><b>{{ finisheduser.ID }}</b
            ><br />
            الاسم :<br /><b>{{ finisheduser.username }}</b
            ><br />
            وقت التسجيل :<br /><b>{{ finisheduser.created }}</b
            ><br />
            رقم التسجيل :<br /><b>{{ finisheduser.number }}</b
            ><br />
            اجمالي عدد المسجلين بالدورة حتي الان :<br /><b>{{
              finisheduser.regristed
            }}</b
            ><br />
            اجمالي العدد المطلوب بالدورة :<br /><b>{{ finisheduser.total }}</b
            ><br />
          </p>
        </v-row>
      </div>
      <div v-if="pastuser">
        <v-row justify="center">
          <h2 class="display-1">غير مصرح لك بالتسجيل مرة اخرة</h2>
        </v-row>
        <v-row justify="center">
          <p>انت مسجل من قبل بالدورة رقم {{ pastuser }}<br /></p>
        </v-row>
      </div>
      <div
        v-if="
          !regdate && !Full && !user && !finisheduser && !pastuser && !notyet
        "
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          dir="rtl"
          @submit.prevent="submit"
        >
          <v-container>
            <v-row justify="center">
              <v-text-field
                v-model="nationalID"
                :rules="nationalIDRules"
                :error-messages="isuseriderror"
                :counter="14"
                label="الرقم القومي"
                name="ID"
                required
                :disable="semisubmitloading"
                :loading="semisubmitloading"
                @keypress="isNumber($event)"
                class="shrink"
              ></v-text-field>
            </v-row>
            <div v-if="timeleft">
              <v-row justify="center">
                <v-text-field
                  v-model="fullname"
                  :rules="nameRules"
                  label="الأسم رباعى على الاقل"
                  name="name"
                  required
                ></v-text-field>
              </v-row>
              <v-row justify="center" v-if="!userinmoodle">
                <v-text-field
                  v-model="username"
                  :error-messages="isusernameerror"
                  label="البريد الجامعي علي منصة التعلم"
                  name="username"
                  required
                ></v-text-field>
              </v-row>
              <v-row justify="center">
                <v-select
                  :items="getfaculity"
                  v-model="faculity"
                  label="الكلية"
                  class="shrink"
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'الكلية مطلوبة']"
                ></v-select>
              </v-row>
              <selectsp v-if="faculity" :sp="getnextsp" />
              <v-row justify="center">
                <p>
                  الوقت المتبقي لك للتسجيل <br />
                  {{ timeleft }}
                </p>
              </v-row>
            </div>
            <v-row justify="center">
              <v-btn
                :disabled="buttonsubmitdisable"
                type="submit"
                color="primary"
              >
                {{ timeleft ? "تسجيل" : "التالي" }}
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <v-row v-if="onlineusers" class="my-5" justify="center">
        <p>
          عدد الاشخاص حاليا {{ onlineusers }}
          <br />
          عدد المسجلين بالدورة
          <!-- {{ totalregristedneeded }} /{{ totalregristedneeded }} -->
          {{ totalregristed }} / {{ totalregristedneeded }}
        </p>
      </v-row>
    </div>
    <v-overlay :value="overlaySend">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="errorsnackbar">
      حدث خطأ اثناء الاتصال
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorsnackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<style scoped>
.v-text-field {
  max-width: 302px;
}
</style>

<script>
import { getfaculity } from "../scripts/faculity.js";
import selectsp from "../components/selectsp";
import io from "socket.io-client";
const socket = io("https://mili-23.azurewebsites.net");
// const socket = io("https://aswu-military.azurewebsites.net");
// const socket = io("https://aswu-mili-2022.herokuapp.com/");
// const socket = io("https://aswu.herokuapp.com");

export default {
  mounted: function() {
    socket.on("online", (data) => {
      this.onlineusers = data;
    });
    socket.on("totalregristed", (data) => {
      this.totalregristed = data;
    });
    socket.on("wait", (data) => {
      this.user = data;
    });
    socket.on("state", (data) => {
      this.user = undefined;
      this.regdate = undefined;
      this.timeleft = undefined;
      if (this.waitinterval) {
        clearInterval(this.waitinterval);
      }
      if (this.timeoutinterval) {
        clearInterval(this.timeoutinterval);
      }
      this.totalregristedneeded = data.totalregristedneeded;
      this.totalregristed = data.totalregristed;
      switch (data.State) {
        case "regdate":
          this.timeanimation("waitinterval", "regdate", data.regdate);
          break;

        case "Full":
          this.Full = data.Full;
          break;

        case "notyet":
          this.notyet = true;
          break;

        // case "new":
        //   break;
      }
      this.overlayLoad = false;
    });
    socket.on("idresult", async (data) => {
      switch (data.registerIdState) {
        case "idError":
          this.errorsnackbar = true;
          break;

        case "exists":
          this.finisheduser = data.user;
          break;

        case "idBlocked":
          this.userinmoodle = false;
          this.isuseriderror = "هذه الدورة غير مخصصة لفرقتك";
          break;

        case "past":
          this.pastuser = data.number;
          break;

        case "start":
          this.user = undefined;
          this.userinmoodle = data.userinmoodle;
          this.timeanimation("timeoutinterval", "timeleft", data.timeleft);
          break;

        case "wait":
          this.user = data.user;
          this.userinmoodle = data.userinmoodle;
          break;
      }
      this.semisubmitloading = false;
      this.buttonsubmitdisable = false;
    });
    socket.on("result", (data) => {
      switch (data.registerIdState) {
        case "idError":
          this.errorsnackbar = true;
          break;

        case "usernameRequired":
          this.userinmoodle = false;
          this.isusernameerror = "يرجي كتابة اسم المستخدم";
          break;

        case "usernameError":
          this.userinmoodle = false;
          this.isusernameerror = "اسم المستخدم او البريد غير موجود";
          break;

        case "exists":
          this.finisheduser = data.user;
          break;

        case "idBlocked":
          this.userinmoodle = false;
          this.isuseriderror = "هذه الدورة غير مخصصة لفرقتك";
          break;

        case "past":
          this.pastuser = data.number;
          break;

        case "errorNotFound":
          this.errorsnackbar = true;
          break;

        case "wait":
          this.user = data.user;
          break;

        case "Finished":
          this.finisheduser = data.user;
          break;
      }
      this.overlaySend = false;
      this.buttonsubmitdisable = false;
    });
  },
  beforeDestroy: function() {
    if (this.waitinterval) {
      clearInterval(this.waitinterval);
    }
  },
  components: {
    selectsp,
  },
  data: () => ({
    valid: false,
    overlayLoad: true,
    overlaySend: false,
    waitinterval: undefined,
    timeoutinterval: undefined,
    regdate: undefined,
    timeleft: undefined,
    userinmoodle: undefined,
    Full: undefined,
    notyet: undefined,
    onlineusers: undefined,
    totalregristed: undefined,
    totalregristedneeded: undefined,

    isusernameerror: undefined,
    isuseriderror: undefined,
    errorsnackbar: false,
    user: undefined,
    finisheduser: undefined,
    pastuser: undefined,
    semisubmitloading: false,
    buttonsubmitdisable: false,
    nationalID: "",
    username: "",
    nationalIDRules: [
      (v) => !!v || "الرقم القومي مطلوب",
      (v) => v.length <= 14 || "الرقم القومي مكون من 14 خانة فقط",
      (v) => v.length >= 14 || "",
      (v) => {
        const pattern = /^[23](?:[0-9]{2}(?:(?:(?:0[13578]|1[02])(?:0[1-9]|[12][0-9]|3[01]))|(?:(?:0[469]|11)(?:0[1-9]|[12][0-9]|30))|(?:02(?:0[1-9]|1[0-9]|2[0-9]))))(?:0[1-4]|[12][1-9]|3[1-5]|88)[0-9]{3}[13579][1-9]$/;
        return pattern.test(v) || "تاكد من الرقم القومي";
      },
    ],
    fullname: "",
    nameRules: [
      (v) => !!v || "الاسم مطلوب",
      (v) => {
        const pattern = /^[ء-ي ]+$/;
        return pattern.test(v) || "يرجي ادخال الاسم باللغة العربية";
      },
      (v) => {
        const pattern = /^(?:[ء-ي]{2,15} ){2}[ء-ي]{2,15} ?(?:(?: ?[ء-ي]{2,15} ?){1,3})?$/;
        return pattern.test(v) || "يرجي ادخال الاسم ثلاثي";
      },
    ],
    faculity: "",
  }),
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) //&&charCode !== 46 // the dot
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    submit: async function() {
      this.buttonsubmitdisable = true;
      var result = this.$refs.form.validate();
      if (result) {
        const formData = new FormData(document.querySelector("form"));
        var prams = {};
        for (var pair of formData.entries()) {
          prams[pair[0]] = pair[1];
        }
        if (!this.timeleft) {
          this.semisubmitloading = true;
          socket.emit("registerID", prams);
        } else {
          this.overlaySend = true;
          socket.emit("registerAll", prams);
        }
      } else {
        this.buttonsubmitdisable = false;
      }
    },
    timeanimation: function(interval, timeleft, distance) {
      this[timeleft] = getTimeLeftString(distance);

      // Update the count down every 1 second
      const ms = 1000;

      this[interval] = setInterval(() => {
        // Display the result in the element with id="timeleft"
        this[timeleft] = getTimeLeftString(distance);

        distance -= ms;
        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(this[interval]);
          this[timeleft] = undefined;
        }
      }, ms);
    },
  },
  computed: {
    getfaculity: function() {
      return getfaculity("").specializations;
    },
    getnextsp: function() {
      return getfaculity(`${this.faculity}`);
    },
  },
};
const getTimeLeftString = (distance) => {
  // Time calculations for days, hours, minutes and seconds
  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return days + "d " + hours + "h " + minutes + "m " + seconds + "s";
};
// const delay = millis =>
//   new Promise(resolve => {
//     setTimeout(() => resolve(), millis);
//   });
</script>
